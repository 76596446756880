<template lang="pug">
div
  page-header(:title='title')
  form(@submit.prevent='gravar')
    .modal-body
      .form-horizontal
        vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
        input-form-text(disabled, textInput='CPF', :required='true', v-model='form.cpf', :value='form.cpf')
        input-form-text(textInput='E-mail', :required='true', v-model='form.email', :value='form.email')
        input-form-text(textInput='Nome', :required='true', v-model='form.name', :value='form.name')
        input-form-text(type='password', textInput='Senha', :required='true', v-model='form.password', :value='form.password')
    footer-button(:disabled='isActive')
</template>

<script>
import { mapGetters } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import req from '@/components/request'
import { sucesso, erro } from '@/components/Message'

export default {
  components: {
    VueElementLoading,
    PageHeader,
    InputFormText,
    FooterButton
  },
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Minha Conta',
      form: {
        id: '',
        cpf: '',
        email: '',
        name: '',
        password: ''
      },
      isActive: false
    }
  },
  methods: {
    gravar() {
      const body = this.form
      req('/minha-conta', 'post', body)
        .then(() => {
          sucesso('Atualizado...')
        })
        .catch(response => {
          console.log('error: ' + response)
          erro()
        })
    }
  },
  created() {
    this.isActive = true
    new Promise(resolve => {
      this.form.id = this.user.id
      this.form.cpf = this.user.cpf
      this.form.email = this.user.email
      this.form.name = this.user.name
      resolve()
    }).then(() => {
      this.isActive = false
    })
  }
}
</script>

<style lang="stylus" scoped></style>
